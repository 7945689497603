import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Router from 'next/router'
import Button from '../components/Button'
import Input from '../components/Input'
import { TextLink } from '../components/typography'
import { useUser } from '../hooks'
import { setFlash } from '../redux/actions'

export default function LoginPage() {
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.loading)
  const [user, { mutate }] = useUser()

  const [password, setPassword] = useState('')
  const [username, setUsername] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()

    const res = await fetch('/api/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ password, username }),
    })

    if (res.status === 200) {
      const userObj = await res.json()
      // set user to useSWR state
      mutate(userObj)
    } else {
      dispatch(setFlash('Incorrect username or password', 'error'))
    }
  }

  useEffect(() => {
    // redirect to home if user is authenticated
    if (user) Router.push('/')
  }, [user])

  return (
    <form onSubmit={handleSubmit}>
      <Input
        autoComplete="username"
        autoFocus
        label="Username or email"
        name="username"
        required
        onChange={(e) => setUsername(e.target.value)}
        value={username}
      />

      <Input
        autoComplete="current-password"
        label="Password"
        name="password"
        required
        type="password"
        onChange={(e) => setPassword(e.target.value)}
        value={password}
      />

      <div className="mb4">
        <TextLink href="/forgot-password">Forgot your password?</TextLink>
      </div>

      <Button disabled={loading} type="submit">
        Log in
      </Button>
    </form>
  )
}
