import styled from '@emotion/styled'
import InputError from './InputError'

const width = '100%'
const transTime = '100ms'

const FormBase = styled.div`
  position: relative;
  margin: 45px 0;

  textarea {
    resize: none;
  }

  input,
  textarea {
    background: none;
    color: ${({ theme }) => theme.colors.text};
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    display: block;
    border: none;
    width: ${width};
    border-radius: 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};

    &:focus {
      outline: none;
    }

    &:focus ~ label,
    &:valid ~ label {
      top: -14px;
      font-size: 12px;
      color: ${({ theme }) => theme.colors.blue};
    }

    &:focus ~ .bar:before {
      width: ${width};
    }
  }

  .bar {
    position: relative;
    display: block;
    width: ${width};

    &:before {
      content: '';
      height: 2px;
      width: 0;
      bottom: 0px;
      position: absolute;
      background: ${({ theme }) => theme.colors.blue};
      transition: ${transTime} ease all;
      left: 0%;
    }
  }
`

const FormLabel = styled.label`
  color: ${({ theme }) => theme.colors.text};
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: ${transTime} ease all;
`

const Input = ({
  autocomplete,
  error,
  label,
  maxLength,
  name,
  onBlur,
  onChange,
  placeholder,
  required,
  type,
  value,
}) => (
  <FormBase>
    <input
      autoComplete={autocomplete}
      maxLength={maxLength}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
      name={name}
      type={type}
      required={required}
    />
    <span className="bar" />
    {label && <FormLabel>{label}</FormLabel>}
    {error && <InputError error={error} />}
  </FormBase>
)

Input.defaultProps = {
  autoComplete: 'off',
  error: null,
  onBlur: () => {},
  onChange: () => {},
  placeholder: '',
  required: false,
  type: '',
  value: '',
}

export default Input
