import styled from '@emotion/styled'

const Container = styled.div`
  color: ${({ theme }) => theme.colors.red};
  margin-left: 0.25rem;
  margin-top: 0.25rem;
`

const InputError = ({ error }) => <Container>{error}</Container>

export default InputError
